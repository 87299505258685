<template>
  <div class="app-container">
    <el-row class="grid-content bg-purple-dark">
      <el-col :span="20" class="bg-purple-title">审批基本信息</el-col>
    </el-row>
    <el-form ref="approvalForm" :model="approvalForm" :rules="rules" label-width="120px">
      <el-row :span="24">
        <el-col :span="8">
          <el-form-item label="审批单号" prop="approvalCode">
            <el-input v-model="approvalForm.approvalCode" :placeholder="$t('page.inputPlaceholder')" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="关联单号" prop="relationCode">
            <el-input v-model="approvalForm.relationCode" :placeholder="$t('page.inputPlaceholder')" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="审批类型">
            <el-select v-model="approvalForm.approvalType" :placeholder="$t('page.selectPlaceholder')" disabled>
              <el-option label="采购计划" value="1" />

            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="状态" prop="status">
            <el-select v-model="approvalForm.status" :placeholder="$t('page.selectPlaceholder')" disabled>
              <el-option label="运营审批中" value="0" />
              <el-option label="计划部批中" value="1" />
              <el-option label="财务部批中" value="2" />
              <el-option label="采购审批中" value="3" />
              <el-option label="审批通过" value="9" />
              <el-option label="审批拒绝" value="10" />

            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="审批人" prop="approvalRole">
            <el-input v-model="approvalForm.approvalRole" :placeholder="$t('page.inputPlaceholder')" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="备注" prop="remarks">
            <el-input v-model="approvalForm.remarks" type="textarea" :placeholder="$t('page.inputPlaceholder')" />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-row class="grid-content bg-purple-dark">
      <el-col :span="20" class="bg-purple-title">审批明细</el-col>
    </el-row>
    <el-table
      ref="addOutboundTable"
      class="mb-3"
      :data="approvaltable"
      :header-cell-style="{background:'#fafafa'}"
      max-height="600px"
    >
      <el-table-column
        :label="$t('page.No')"
        type="index"
        width="80"
        align="center"
      >
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column
        prop="style"
        label="Style"
        width="120"
        align="center"
      />
      <el-table-column
        prop="productPositionI18"
        label="产品定位"
        width="120"
        align="center"
      />
      <el-table-column
        prop="orderTargetDay"
        label="下单目标天数"
        width="120"
        align="center"
      />
      <el-table-column
        prop="ltDay"
        label="L/T时间"
        width="120"
        align="center"
      />
      <el-table-column
        prop="sellPrice"
        label="销售单价"
        align="center"
      />
      <el-table-column
        prop="purchasePrice"
        label="采购单价"
        width="100"
        align="center"
      />
      <el-table-column
        prop="purchaseAmount"
        label="采购金额"
        width="100"
        align="center"
      />
      <el-table-column
        prop="profitRate"
        label="利润率"
        width="100"
        align="center"
      />

      <el-table-column
        prop="expectPairs"
        label="数量"
        width="100"
        align="center"
      />
      <el-table-column
        prop="totalInventoryPairs"
        label="库存总数量"
        width="110"
        align="center"
      />
      <el-table-column
        prop="rps"
        label="PRS数量"
        width="100"
        align="center"
      />
      <el-table-column
        prop="mrs"
        label="MRS数量"
        width="100"
        align="center"
      />
      <el-table-column
        prop="turnover"
        label="标准Turnover"
        width="100"
        align="center"
      />
      <el-table-column
        prop="expcetTurnover"
        label="预计Turnover"
        width="100"
        align="center"
      />
      <el-table-column
        prop="totalInventoryAmount"
        label="资金占用"
        width="100"
        align="center"
      />
      <el-table-column
        prop="halfYearRefundRate"
        label="近半年退货率"
        width="100"
        align="center"
      />
    </el-table>
    <el-row class="grid-content bg-blue" type="flex" justify="center">
      <!-- <el-button @click="cancle()">{{ $t('title.cancel') }}</el-button> -->
      <el-button v-if="shows" type="primary" :loading="saveloading" @click="save()">通过</el-button>
      <el-button v-if="shows" type="primary" :loading="Refuseloading" @click="onSubmit()">拒绝</el-button>
    </el-row>
  </div>
</template>
<script>
import { approvalDetail, approvalsubmit } from '@/api/scm-api'

export default {
  data() {
    return {
      shows: true,
      fordisabled: false, // 禁用文本框
      approvalForm: {
        status: '',
        approvalType: '1'
      },
      saveloading: false,
      Refuseloading: false,
      approvaltable: [],
      pager: {
        size: 10,
        current: 1,
        total: 0
      },
      row: '',
      rules: {

      }
    }
  },
  computed: {
  },
  mounted() {
    this.status = this.$route.meta.status
    if (this.$route.query && this.$route.query.disabled) {
      this.fordisabled = true
    }
    this._approvalDetail()
  },
  methods: {

    async _approvalDetail(query) {
      const { relationCode, approvalCode } = this.$route.query
      const { datas } = await approvalDetail({ relationCode, approvalCode })
      this.approvalForm = datas
      this.approvalForm.status = String(datas.status)
      this.approvalForm.approvalType = '1'
      this.approvaltable = datas.detailList
    },
    save() {
      const { approvalCode, status } = this.$route.query
      this._approvalsave({ approvalCode, status, approvalResult: 0 })
    },
    async _approvalsave(data) {
      this.saveloading = true
      const { code, msg } = await approvalsubmit(data)
      if (code === 0) {
        this.$notify({
          title: msg,
          message: msg,
          type: 'success'
        })
        this.shows = false
        this.saveloading = false
      } else { // 其他状态
        this.$notify({
          title: msg,
          message: msg,
          type: 'success'
        })
        this.saveloading = false
      }
    },
    onSubmit() {
      const { approvalCode, status } = this.$route.query
      this._approvalsubmit({ approvalCode, status, approvalResult: 1 })
    },
    async _approvalsubmit(data) {
      this.Refuseloading = true
      const { code, msg } = await approvalsubmit(data)
      if (code === 0) {
        this.$notify({
          title: msg,
          message: msg,
          type: 'success'
        })
        this.shows = false
        this.Refuseloading = false
      } else { // 其他状态
        this.$notify({
          title: msg,
          message: msg,
          type: 'success'
        })
      }
    },

    getTotal(param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = this.$t('合计')
          return
        }
        if (column.property === 'actualPairs') {
          const values = data.map(item => Number(item[column.property]))
          if (!values.every(value => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev + curr
              } else {
                return prev
              }
            }, 0)
          } else {
            sums[index] = ''
          }
        } else {
          sums[index] = ''
        }
      })
      return sums
    }

  }
}
</script>
